//import logo from './logo.svg';
import './App.css';
import jess from './Jessica.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      

      <div class = "headerContainer">
        <span>
        <h1 class = "hi">Hi there,</h1>
        <h1>I'm Jessica Vogt</h1>
        </span>
        <img src={jess} class = "image" alt="jess" />
       </div>
          
        
      </header>
    </div>
  );
}

export default App;
